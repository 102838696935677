import React from "react"
import { graphql } from "gatsby"
import { MainLayout } from "../components/layouts/MainLayout"
import Header from "../components/header/Header";
import { Post } from "../components/post/Post";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
    <Header siteTitle={data.site.siteMetadata.title} />
    <MainLayout>
      <div className="blog-post-container">
        <div className="post">
          <Post title={frontmatter.title} content={html} date={frontmatter.date} tags={frontmatter.tags}>
            {frontmatter.title}
          </Post>

        </div>
      </div>
    </MainLayout>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`